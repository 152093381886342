<template>
  <div class="shadow-none bg-white py-6 px-4 rounded-xl">
    <TsMedia
      :append-class="props.variant === 'alreadySubscribed' ? 'mb-0' : 'mb-3'"
    >
      <TsMediaStart v-if="!props.variant">
        <div
          :class="
            props.variant === 'alreadySubscribed'
              ? 'text-center'
              : 'flex items-center'
          "
        >
          <div class="mr-1">
            <TsIcon
              size="24"
              class="text-success"
              name="mdi:tick-circle-outline"
            />
          </div>
          <div>
            <TsTypography
              variant="h4"
              weight="semibold"
              appendClass="text-idle-black mb-0"
              >{{useTranslation("keepYouInformed","Wij houden u op de hoogte")}}</TsTypography
            >
          </div>
        </div>
      </TsMediaStart>
      <TsMediaEnd
        ><TsIcon
          @click="emit('close')"
          name="bitcoin-icons:cross-filled"
          size="26"
          class="text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
      /></TsMediaEnd>
    </TsMedia>
    <div v-if="props.variant" class="flex flex-col items-center text-center">
      <div>
        <TsIcon
          size="40"
          color="success"
          class="text-green-600"
          name="mdi:tick-circle-outline"
        />
      </div>
      <div>
        <TsTypography
          variant="h2"
          weight="semibold"
          appendClass="text-center mb-0 mt-3"
        >
          {{useTranslation("alreadySubscribed", "U bent al geabonneerd op voorraadmeldingen")}}</TsTypography
        >
      </div>
    </div>
    <TsTypography
      v-if="!props.variant"
      appendClass="mb-4 text-natural-grey"
      variant="h5"
      weight="medium"
      >
       {{ useTranslation("when","wanneer") }} <span class="text-black"> {{props.productName+" "}}</span>{{ useTranslation("becomesAvailable","beschikbaar komt, ontvangt u een e-mailmelding op")}} <span class="text-black">{{props.email}}</span>
      </TsTypography
    >
    <TsDivider appendClass="mb-4" />
    <TsTypography
  :appendClass="
    twMerge(
      '!mb-2 text-grey',
      props.variant === 'alreadySubscribed' && 'text-center text-grey'
    )
  "
  variant="h5"
>
  {{useTranslation("noLongerNotification","Wilt u geen meldingen meer ontvangen?")}}
</TsTypography>
    <div
      :class="props.variant === 'alreadySubscribed' ? 'text-center' : 'flex'"
    >
      <TsTypography :data-testid="'unsubscribe-' + randomId"
        :appendClass="
          twMerge(
            'text-info underline hover:no-underline underline-offset-1 cursor-pointer',
            props.variant === 'alreadySubscribed' &&
              'text-info underline-offset-1 cursor-pointer text-center'
          )
        "
        @click="unSubscribeProduct"
        variant="h5"
        weight="semibold"
        >{{useTranslation("unSubscribe","Uitschrijven")}}</TsTypography
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";

const productStore = useProductStore();
const trolleyStore = useTrolleyStore();
const accountStore = useAccountStore();
type Props = {
  productName?: string;
  email?: string;
  variant?: string;
};
const randomId = ref("");

onMounted(() => {
  randomId.value = useRandomUUID();
});
const props = withDefaults(defineProps<Props>(), {
  productName: "",
  email: "",
});
const emit = defineEmits<{
  close: any;
}>();
const unSubscribeProduct = async () => {
     await productStore.unSubscribeProduct(productStore.notify_product_code);
     productStore.subscription_successful_modal_visible = false;
     accountStore.stock_notifications = accountStore.stock_notifications.filter((notification:any)=> notification.product_id!==productStore.notify_product_code)
};
</script>
